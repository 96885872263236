import { keunggulanList } from "../../utils/beranda/keunggulanUtils";
import KeunggulanCard from "./KeunggulanCard";

export default function Keunggulan() {
  return (
    <section className="flex h-full w-full justify-center bg-gradient-to-b from-[#F6FCFF] via-[#F6FCFF] py-10 px-4 md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="flex w-full max-w-[1260px] flex-col">
        <h2 className="bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text px-4 text-center font-nunito text-2xl font-black text-transparent md:text-4xl">
          Keunggulan Program Belajar Wardaya College
        </h2>

        <ul className="grid w-full grid-cols-1 gap-5 pt-12 md:grid-cols-2">
          {keunggulanList.map((item, i) => (
            <KeunggulanCard
              key={i}
              image={item.image}
              title={item.title}
              text={item.text}
            />
          ))}
        </ul>
      </div>
    </section>
  );
}
