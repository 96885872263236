import { useState, useEffect } from "react";
import { useMutation, useQuery } from "urql";
import { gql } from "@urql/core";
import toast from "react-hot-toast";
import { client } from "../../graphql/client";

const query = gql`
  query MyQuery {
    cms_slideshows(
      where: { is_active: { _eq: true }, deleted_at: { _is_null: true } }
      order_by: { created_at: desc }
      limit: 2
    ) {
      is_active
      picture
      link
      title
      id
    }
  }
`;

export async function getSlideShow() {
  const { data } = await client.query(query).toPromise();

  const temps = data?.cms_slideshows.map((item) => {
    return {
      id: item.id,
      title: item.title,
      link: item.link,
      picture: item.picture,
      is_active: item.is_active,
    };
  });

  return temps;
}

const mutate = gql`
  mutation MyMutation($object: [cms_newsletters_insert_input!]!) {
    insert_cms_newsletters(objects: $object) {
      returning {
        email
      }
    }
  }
`;

export default function useHero(emailRef) {
  const [{ fetching }, handlePostEmail] = useMutation(mutate);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (emailRef.current.value) {
        const { error: postError } = await handlePostEmail({
          object: { email: emailRef.current.value, created_at: new Date() },
        });
        if (postError) {
          throw postError;
        }
        toast.success("email anda berhasil ditambahkan");
        emailRef.current.value = "";
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetching,
    isLoading,
    handleSubmit,
  };
}
