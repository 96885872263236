import { testiList } from "../../utils/beranda/testiUtils";
import TestiCard from "./TestiCard";

export default function Testimoni() {
  return (
    <section className="w-full py-10 px-4 md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="container mx-auto w-full max-w-[1260px] items-center space-y-4 py-5">
        <div className="flex w-full flex-col space-y-2 text-center ">
          <h2 className="bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text font-nunito text-2xl font-black text-transparent md:text-4xl ">
            Testimoni
          </h2>
          <p className="space-y-5 font-nunito text-sm font-semibold text-[#142D59] md:text-xl">
            Testimoni Alumni Wardaya yang diterima di universitas terbaik
          </p>
        </div>

        <div className="overflow-hidden lg:py-5">
          <div className="w-full overflow-visible">
            <div className="hide-scrollbar flex w-full overflow-x-scroll pt-6 pb-10">
              <ul className="flex gap-4 px-4">
                {testiList.map((item, i) => (
                  <TestiCard
                    key={i}
                    text={item.text}
                    image={item.image}
                    nama={item.nama}
                    asal={item.asal}
                    jurusan={item.jurusan}
                    univ={item.univ}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
        div.container {
          max-height: calc(100vh-76.5px);
        }
        `}
      </style>
    </section>
  );
}
