export const testiList = [
  {
    nama: "Gabriela Erin Mariangel",
    text: "“ Gurunya baik banget, kelasnya sangat membantu, saya belajar banyak hal. Terima kasih buat semuanya!!! ”",
    asal: "SMAK 1 Penabur Jakarta 2020/2021",
    jurusan: "Biomedical Engineering",
    univ: "Massachusetts Institute of Technology, Class of 2025",
    href: "/testi/1",
    image: "/images/testi1.webp",
    img: "/images/testi1.png",
    backimage: "/images/petiktesti.svg",
  },
  {
    nama: "Abdullah Naji",
    text: "“ Kelas Top Universitas sangat membantu persiapan tes masuk universitas. Materi Matematika dan Fisika di Wardaya bisa dijadikan headstart untuk menghadapi soal-soal di universitas nanti. ”",
    asal: "SMAN Unggulan M. H. Thamrin 2020/2021",
    jurusan: "Mathematical Sciences",
    univ: "Kyoto University, Class of 2025",
    href: "/testi/1",
    image: "/images/testi2.webp",
    img: "/images/testi2.png",
    backimage: "/images/petiktesti.svg",
  },
  {
    nama: "Michelle Chrisalyn Djunaidi",
    text: "“ Kelas Top Universitas memperkenalkan saya kepada kurikulum luar negeri yang jauh berbeda dengan kurikulum Indonesia serta mempersiapkan saya menghadapi ujian masuk universitas luar negeri dengan baik ”",
    asal: "SMAK Ipeka Puri 2020/2021",
    jurusan: "Information Systems Technology and Design",
    univ: "Singapore University of Technology and Design, Class of 2025",
    href: "/testi/1",
    image: "/images/testi6.webp",
    img: "/images/testi6.png",
    backimage: "/images/petiktesti.svg",
  },
];
