import Image from "next/image";
import Link from "next/link";

export default function ProgramFilter(props) {
  return (
    <li className="flex flex-col items-center justify-center">
      <Image
        src={props.image}
        alt={props.name}
        className="bg-cover bg-center"
        height={350}
        width={350}
        priority={true}
      />
      <div className="flex flex-col items-center gap-2 text-center">
        <div className="h-40">
          <p
            className="font-nunito text-sm font-semibold md:text-base"
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></p>
        </div>
        <Link href={`/program/${props.name}`} target="_blank">
          <a>
            <button className="h-12 w-60 rounded-md bg-[#134369] font-nunito text-sm font-bold capitalize text-white hover:opacity-80">
              {props.name}
            </button>
          </a>
        </Link>
      </div>
    </li>
  );
}
