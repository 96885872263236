import { useState } from "react";
import { useDataProgram } from "../../service/beranda/useDataProgram";

import ProgramList from "./ProgramList";
import ProgramFilter from "./ProgramFilter";

export default function Program({ programs }) {
  const [selected, setSelected] = useState("olimpiade");

  const programFilter = (programList = []) => {
    return programList.filter((e) => e.name === selected);
  };

  return (
    <section className="flex h-full w-full justify-center py-10 px-4 md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="flex w-full max-w-[1260px] flex-col gap-2 lg:gap-0">
        <div className="flex flex-col items-center space-y-2 text-center lg:items-start lg:text-left">
          <h2 className="h-12 bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text font-nunito text-2xl font-black text-transparent md:text-4xl">
            Program Kami
          </h2>
          <p className="h-10 font-nunito text-sm font-semibold text-[#142D59] md:text-xl">
            Program program terbaik yang disediakan Wardaya College
          </p>
        </div>

        <div className="grid grid-cols-1 justify-between lg:grid-cols-2">
          <ul className="col-span-1 grid w-full grid-cols-2">
            {programs?.map((item, i) => (
              <ProgramList
                key={i}
                name={item.name}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </ul>
          <ul className="col-span-1 h-full w-full p-2 ">
            {programFilter(programs).map((item, i) => (
              <ProgramFilter
                key={i}
                name={item.name}
                image={item.image}
                description={item.description}
              />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
