export const keunggulanList = [
  {
    image: "/images/keunggulan-kualitas.webp",
    img: "/images/keunggulan-kualitas.png",
    title: "Kualitas Pengajar dan Materi",
    text: "Pengajar berpendidikan S2 dan materi berstandar internasional.",
  },
  {
    image: "/images/keunggulan-terintegrasi.webp",
    img: "/images/keunggulan-terintegrasi.png",
    title: "Program Lengkap dan Terintegrasi",
    text: "Program belajar lengkap dan terintegrasi dengan bimbingan pendaftaran ke universitas terbaik nasional & internasional.",
  },
  {
    image: "/images/keunggulan-rekamjejak.webp",
    img: "/images/keunggulan-rekamjejak.png",
    title: "Rekam Jejak yang Sangat Baik",
    text: "100+ pemenang medali KSN 2021 dan penerimaan siswa oleh universitas-universitas terbaik dunia",
  },
  {
    image: "/images/keunggulan-simulasi.webp",
    img: "/images/keunggulan-simulasi.png",
    title: "Bimbingan Masuk Sekolah Unggulan",
    text: "Simulasi UTBK dan persiapan ujian masuk sekolah dan perguruan tinggi unggulan",
  },
  // {
  //   image: "/images/keunggulan-pilihan.webp",
  //   img: "/images/keunggulan-pilihan.png",
  //   title: "Pilihan Kursus Sangat Lengkap",
  //   text: "",
  // },
];
