import Image from "next/image";

export default function TestiCard(props) {
  return (
    <li className="relative flex h-[18.5rem] w-[19rem] flex-col items-center justify-between rounded-[10px] border bg-[#142D59] p-6 py-[36px] text-[#FFFFFF] shadow-[0_4px_20px_0_rgba(0,0,0,0.2)] shadow-[#DCE5ED] lg:h-[22rem] lg:w-[24rem]">
      <p className="text-center font-nunito text-xs leading-normal lg:text-base">
        {props.text}
      </p>
      <div className="flex h-32 flex-col justify-between space-y-2 font-nunito lg:h-36">
        <div className="flex w-full items-center space-x-3">
          <Image
            src={props.image}
            alt={props.text}
            height={80}
            width={80}
            priority={true}
          />
          <div className="w-3/4 flex-col space-y-1">
            <p className="text-sm font-extrabold lg:text-base">{props.nama}</p>
            <p className="text-xs font-semibold lg:text-sm ">{props.asal}</p>
          </div>
        </div>
        <div className="flex w-full flex-col text-left text-sm font-semibold lg:text-base">
          <p className="">{props.jurusan}</p>
          <p className="">{props.univ}</p>
        </div>
      </div>
    </li>
  );
}
