import { useState, useEffect } from "react";
import { useQuery } from "urql";
import { gql } from "@urql/core";
import { client } from "../../graphql/client";

const query = gql`
  query MyQuery(
    $limit: Int
    $offset: Int
    $order: [cms_events_order_by!]
    $title: String
    $date: timestamptz
  ) {
    cms_events(
      where: { title: { _ilike: $title }, time: { _gte: $date } }
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      id
      title
      location
      created_at
      picture
      time
      url
      description
    }
    cms_events_aggregate(
      where: { title: { _ilike: $title }, time: { _gte: $date } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const queryServer = gql`
  query MyQuery($limit: Int) {
    cms_events(order_by: { created_at: desc }, limit: $limit) {
      id
      title
      location
      created_at
      picture
      time
      url
      description
    }
  }
`;

export async function getAcaraHomePage() {
  const { data } = await client.query(queryServer, { limit: 4 }).toPromise();

  const temps = data?.cms_events.map((item) => {
    return {
      id: item.id,
      title: item.title,
      location: item.location,
      created_at: item.created_at,
      picture: item.picture,
      time: item.time,
      url: item.url,
      description: item.description,
    };
  });

  return temps;
}

export default function useAcara(limit, date, page) {
  const [selected, setSelected] = useState("Acara Terbaru");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");

  const [orderBy, setOrderBy] = useState({
    order: "time",
    isAsc: false,
  });

  const [{ data: tempData, fetching }] = useQuery({
    query: query,
    variables: {
      title: search.length > 0 ? "%" + search + "%" : "%%",
      date: selected === "Acara Yang Akan Datang" ? date : "1900-01-01",
      limit: limit,
      offset: limit * (page - 1),
      order: {
        [orderBy.order]: orderBy.isAsc ? "asc" : "desc",
      },
    },
  });

  const handleOrder = (e) => {
    if (e.value === "time0" || e.value === " date") {
      setSelected(e.text);
      setOrderBy((prev) => ({ ...prev, isAsc: false }));
      return selected, orderBy;
    }

    setSelected(e.text);
    setOrderBy((prev) => ({ ...prev, isAsc: true }));
  };

  useEffect(() => {
    if (tempData) {
      const temps = [];
      for (const item of tempData.cms_events) {
        temps.push({
          id: item.id,
          title: item.title,
          location: item.location,
          created_at: item.created_at,
          picture: item.picture,
          time: item.time,
          url: item.url,
          description: item.description,
        });
      }
      setData(temps);
      setTotal(tempData.cms_events_aggregate.aggregate.count);
    }
  }, [tempData]);

  return {
    data,
    fetching,
    selected,
    setSelected,
    total,
    setTotal,
    search,
    setSearch,
    handleOrder,
  };
}
