import Head from "next/head";
import Hero from "../components/beranda/Hero";
import Keunggulan from "../components/beranda/Keunggulan";
import Instagram from "../components/beranda/Instagram";
import Testimoni from "../components/beranda/Testimoni";
import Program from "../components/beranda/Program";
import BeritaAcara from "../components/beranda/BeritaAcara";
import { getSlideShow } from "../service/beranda/useHero";
import { getBeritaHomePage } from "../service/publikasi/useBerita";
import { getAcaraHomePage } from "../service/publikasi/useAcara";
import { getPrograms } from "../service/beranda/useDataProgram";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loading from "../components/Loading";

export default function Home() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const slides = await getSlideShow();
      const news = await getBeritaHomePage();
      const events = await getAcaraHomePage();
      const programs = await getPrograms();

      console.log({ news, events });

      setData({
        slides: slides,
        news: news,
        events: events,
        programs: programs,
      });
    } catch (err) {
      toast.error(err.toString());
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <main className="flex w-full flex-col items-center">
        <Head>
          <title>Wardaya Online by Wardaya College</title>
          <link rel="icon" href="/images/logo1.webp" />
          <meta
            name="description"
            content="Wardaya Online adalah situs resmi untuk mendapatkan informasi terbaru seputar Wardaya College, mengikuti kelas online Wardaya, dan informasi kelas yang ditawarkan."
          />
        </Head>
        <div id="hero" className="relative w-full">
          <Hero data={data?.slides} />
        </div>
        <div id="keunggulan" className="w-full">
          <Keunggulan />
        </div>
        <div id="beritaandacara" className="w-full">
          <BeritaAcara news={data?.news} events={data?.events} />
        </div>
        <div id="program" className="w-full">
          <Program programs={data?.programs} />
        </div>
        <div id="testimoni" className="w-full bg-[#F2FBFF]">
          <Testimoni />
        </div>
        <div id="instagram" className="w-full">
          <Instagram />
        </div>
      </main>
      {isLoading && (
        <div className="fixed top-0 left-0 z-[150] flex h-screen w-screen items-center justify-center bg-black bg-opacity-10">
          <Loading fillcolor={"#EA374C"} />
        </div>
      )}
    </>
  );
}
