import { useRef } from "react";
import Card from "./Card";
import Loading from "../Loading";
import Image from "next/image";
import useHero from "../../service/beranda/useHero";

export default function Hero({data}) {
  const emailRef = useRef();
  const { fetching, isLoading, handleSubmit } =
    useHero(emailRef);

  return (
    <>
      <section className="h-full w-full items-center justify-start space-y-5 bg-[url(/images/hero.webp)] bg-cover px-4 pt-24 pb-28 md:space-y-0 md:space-x-3 md:px-8 lg:-mt-32 lg:px-20 lg:pt-72 xl:px-24 2xl:px-32">
        <div className="mx-auto grid max-w-[1260px] grid-cols-1 gap-5 lg:grid-cols-2">
          <div className="flex w-full flex-col items-center space-y-4 lg:items-start">
            <div className="text-center lg:text-start">
              <p className="bg-gradient-to-tr from-[#F5B119] to-[#F8CB66] bg-clip-text font-nunito text-4xl font-extrabold text-transparent lg:text-6xl lg:font-black">
                One purpose,
              </p>
              <p className="bg-gradient-to-tr from-[#F5B119] to-[#F8CB66] bg-clip-text font-nunito text-4xl font-extrabold text-transparent lg:text-6xl lg:font-black">
                one mission,
              </p>
              <p className="bg-gradient-to-tr from-[#F5B119] to-[#F8CB66] bg-clip-text font-nunito text-4xl font-extrabold text-transparent lg:text-6xl lg:font-black">
                one dream
              </p>
            </div>
            <h1 className="text-center font-nunito text-base font-bold text-white lg:text-left lg:text-xl">
              Wardaya institusi pendidikan terdepan di Indonesia
            </h1>
          </div>
          <div className="max-w-full">
            <div className="flex w-full justify-center gap-5 lg:justify-end">
              {data && data.length
                ? data.map((item, i) => (
                    <Card
                      key={i}
                      image={item.picture}
                      href={item.link}
                      title={item.title}
                    />
                  ))
                : null}
            </div>
          </div>
          <div className="mt-0 space-y-2 xl:-mt-12">
            <p className="text-left font-nunito text-xs font-semibold text-white lg:text-base">
              Dapatkan pemberitahuan terbaru kami dengan berlangganan newsletter
            </p>
            <div className="flex w-full items-center justify-between rounded-lg border-2 border-white">
              <input
                ref={emailRef}
                className="w-full bg-transparent px-3 font-nunito text-base font-bold text-white focus:outline-none "
                placeholder="masukkan email @"
                onKeyDown={({ key }) => {
                  if (key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              <button
                onClick={handleSubmit}
                className="flex items-center justify-center bg-white px-3 py-2 focus:outline-none"
              >
                <Image
                  src="/images/mail.svg"
                  alt="mail"
                  height={24}
                  width={24}
                  priority={true}
                />
              </button>
            </div>
          </div>
        </div>
      </section>

      {fetching || isLoading ? (
        <div className="fixed top-0 left-0 z-[150] flex h-screen w-screen items-center justify-center bg-black bg-opacity-10">
          <Loading fillcolor={"#EA374C"} />
        </div>
      ) : null}
    </>
  );
}
