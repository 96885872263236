import React from "react";
import Image from "next/image";

export default function KeunggulanCard(props) {
  return (
    <li className={`col-span-1 mx-auto lg:mx-0`}>
      <div className="flex w-full items-center space-x-4">
        <div className="items-center justify-center rounded-full bg-white p-6 shadow-lg shadow-[#DCE5ED]">
          <Image
            src={props.image}
            alt={props.title}
            height={50}
            width={50}
            priority={true}
          />
        </div>
        <div className="flex w-5/6 flex-col text-[#1C1C1C]">
          <p className="font-nunito text-sm font-bold lg:text-base">
            {props.title}
          </p>
          <p className="w-full font-nunito text-xs font-semibold lg:w-4/5 lg:text-sm">
            {props.text}
          </p>
        </div>
      </div>
    </li>
  );
}
