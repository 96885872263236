import Loading from "../Loading";
import useInstagram from "../../service/beranda/useInstagram";
import InstagramImage from "./InstagramImage";
import Link from "next/link";

export default function Instagram() {
  // const { isLoading, igFilter } = useInstagram();

  return (
    <section className="w-full py-10 px-4 md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="container mx-auto w-full max-w-[1260px] items-center gap-4 py-5 px-2">
        <div className="md:text-none flex flex-col items-center space-y-4 text-center">
          <h2 className="bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text font-nunito text-2xl font-black text-transparent md:text-4xl">
            Ikuti Instagram Kami
          </h2>
          <p className="font-nunito text-sm font-semibold text-[#142D59] md:text-xl">
            Dapatkan berita-berita menarik melalui Instagram{" "}
            <span>
              <Link href={"https://www.instagram.com/wardayacollegeofficial/"}>
                <a target="_blank">@wardayacollegeofficial</a>
              </Link>
            </span>
          </p>
        </div>

        {/* <div className="overflow-hidden">
          <div className="w-full overflow-visible">
            <div className="hide-scrollbar flex w-full overflow-x-scroll">
              <ul className="flex gap-5 py-8 px-4">
                {igFilter !== undefined
                  ? igFilter.map((item, i) => (
                      <InstagramImage
                        key={i}
                        permalink={item.permalink}
                        media_url={item.media_url}
                      />
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div> */}
      </div>

      {/* {isLoading ? (
        <div className="fixed top-0 left-0 z-[150] flex h-screen w-screen items-center justify-center bg-black bg-opacity-10">
          <Loading fillcolor={"#EA374C"} />
        </div>
      ) : null}

      <style>
        {`
        div.container {
          max-height: calc(100vh-76.5px);
        }
        `}
      </style> */}
    </section>
  );
}
