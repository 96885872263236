import Link from "next/link";
import ListBeritaAcara from "./ListBeritaAcara";
import Loading from "../Loading";

export default function BeritaAcara({ news, events }) {
  return (
    <section className="flex h-full w-full items-center justify-center py-10 px-4 md:px-8 lg:px-20 xl:px-24 2xl:px-32">
      <div className="flex max-w-[1260px] flex-col items-center justify-center gap-5">
        <h2 className="bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text px-4 text-center font-nunito text-2xl font-black capitalize text-transparent md:text-3xl">
          berita & acara terbaru
        </h2>
        <div className="grid w-full grid-cols-1 items-start gap-8 md:gap-10 lg:grid-cols-2">
          <div className="flex flex-col items-center justify-center">
            <ul className="flex h-[30rem] w-full flex-col justify-between lg:h-[32rem]">
              {news && news.length
                ? news.map((item, i) => (
                    <ListBeritaAcara
                      key={i}
                      text={"berita"}
                      picture={item.picture}
                      url={item.url}
                      title={item.title}
                      date={item.created_at.split("T")[0]}
                    />
                  ))
                : null}
            </ul>
            <Link href={"/publikasi/berita"}>
              <button className="mt-5 w-1/2 rounded-md bg-[#134369] py-3 font-nunito text-sm font-bold text-white hover:opacity-80">
                Lihat berita lainnya
              </button>
            </Link>
          </div>
          <div className="flex flex-col items-center justify-center">
            {/* <h2 className="bg-gradient-to-tr from-[#024D88] to-[#006ABE] bg-clip-text px-4 text-center font-nunito text-2xl font-black capitalize text-transparent md:text-3xl">
              acara terbaru
            </h2> */}
            <ul className="flex h-[30rem] w-full flex-col justify-between lg:h-[32rem]">
              {events && events.length
                ? events.map((item, i) => (
                    <ListBeritaAcara
                      key={i}
                      text={"acara"}
                      picture={item.picture}
                      url={item.url}
                      title={item.title}
                      date={item.time.split("T")[0]}
                    />
                  ))
                : null}
            </ul>
            <Link href={"/acara"}>
              <button className="mt-5 w-1/2 rounded-md bg-[#134369] py-3 font-nunito text-sm font-bold text-white hover:opacity-80">
                Lihat acara lainnya
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
