export default function ProgramList({ name, selected, setSelected }) {
  return (
    <li
      onClick={() => setSelected(name)}
      className="col-span-1 w-auto cursor-pointer lg:col-span-2"
    >
      <div
        className={`flex w-full items-center justify-start space-x-3 rounded-xl p-2 md:p-3 lg:w-5/6 ${
          name === selected
            ? "bg-[#EDF9FF] shadow-lg shadow-[#E3F6FF]"
            : "hover:bg-[#EDF9FF] hover:shadow-lg hover:shadow-[#E3F6FF]"
        }`}
      >
        <img
          src="/images/daun.svg"
          alt="navigasi-program"
          className="h-5 w-5 lg:h-8 lg:w-8"
        />
        <p className="font-nunito text-sm font-semibold capitalize text-[#1C1C1C] lg:text-xl">
          {name}
        </p>
      </div>
    </li>
  );
}
