import { useState } from "react";
import Link from "next/link";
import Image from "next/image";

export default function ListBeritaAcara({ url, picture, title, date, text }) {
  const [imgSrc, setImgSrc] = useState(picture);
  const tanggal = new Date(date).toLocaleDateString("id", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <li className="group col-span-1 w-full cursor-pointer">
      <Link
        href={`${
          text === "berita" ? `/publikasi/berita/${url}` : `/acara/${url}`
        }`}
      >
        <a className="flex h-full items-center justify-center space-x-3">
          <div className="relative flex items-center justify-center">
            <Image
              src={imgSrc ? imgSrc : setImgSrc("/images/logo.png")}
              alt="berita"
              height={80}
              width={80}
              objectFit="contain"
              priority={true}
              onError={() => setImgSrc("/images/logo.png")}
            />
          </div>
          <div className="flex h-auto w-full flex-col justify-between pt-10 text-left">
            <p className="hide-text font-nunito text-sm font-bold transition duration-200 ease-out group-hover:translate-x-4 group-hover:drop-shadow-lg lg:text-base">
              {title}
            </p>
            <div className="mt-2 flex w-full justify-between">
              <p className="font-sans text-xs font-light text-black">
                Posted by : Admin
              </p>
              <p className="font-sans text-xs font-light text-black">
                {text === "berita" ? "Created at : " : "Time : "} {tanggal}
              </p>
            </div>
            <hr className="mt-3 h-0.5 w-full bg-[#DAE6F1]" />
          </div>
        </a>
      </Link>
      <style>
        {`
          p.hide-text {
            display: -webkit-box;
            max-width: 95%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        `}
      </style>
    </li>
  );
}
