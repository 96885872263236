import Image from "next/image";

export default function Card({ image, href, title }) {
  return (
    <div className="justify-center gap-4 rounded-2xl bg-white p-3">
      <div className="flex items-center justify-center overflow-clip rounded-lg">
        <Image src={image} alt={title} width={224} height={224} />
      </div>

      <div className="mt-3.5 flex items-center justify-center gap-3 text-sm font-extrabold text-[#333333]">
        <a href={href} target="_blank">
          <button className="rounded-lg border-4 border-[#E8E8E8] bg-[#E8E8E8] py-1 px-4 font-nunito text-xs font-extrabold hover:bg-[#228BE6] hover:text-white">
            Open
          </button>
        </a>
      </div>
    </div>
  );
}
